<template>
  <div id="actionCard" class="w-100">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <!-- Delete pdf dialog -->
    <v-dialog v-model="deleteFolderDialog" max-width="390" persistent>
      <v-card :loading="loading || superLoading">
        <v-card-title class="headline"> Please confirm </v-card-title>

        <v-card-text>
          Are you sure you want to delete the "
          <b>{{ folders[selectedFolderIndex] }}</b> " folder ? <br /><br />
          This action cannot be undone.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            :disabled="loading || superLoading"
            dark
            @click.prevent="deleteFolderName(selectedFolderIndex)"
          >
            Delete
          </v-btn>

          <v-btn
            color="primary"
            :disabled="loading || superLoading"
            text
            @click="deleteFolderDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card :loading="loading || superLoading" class="w-100 pb-4 px-4">
      <!-- Actions -->
      <h6 class="text-h6 pt-4">
        <v-icon class="mr-1">mdi-chevron-right</v-icon> Actions:
      </h6>

      <v-container>
        <v-row justify="center">
          <!-- Create Question Column -->
          <v-col cols="12" sm="12" md="6" class="d-flex">
            <v-btn
              to="/create/question"
              class="mx-auto"
              color="pink darken-1"
              dark
              :loading="loading || superLoading"
            >
              <v-icon class="mr-2">mdi-text-box-plus</v-icon>
              Create Question
            </v-btn>
          </v-col>

          <!-- Edit folders Column -->
          <v-col cols="12" sm="12" md="6" class="d-flex">
            <v-dialog v-model="dialog" scrollable max-width="720px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-auto"
                  color="pink darken-1"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  :loading="loading || superLoading"
                >
                  <v-icon class="mr-2">mdi-folder-edit</v-icon>
                  Edit Folders
                </v-btn>
              </template>
              <v-card :loading="loading || superLoading">
                <v-card-title
                  ><v-icon class="mr-2">mdi-folder-edit</v-icon>Edit
                  Folders</v-card-title
                >
                <v-divider></v-divider>
                <v-card-text>
                  <!-- Folder Form -->
                  <v-form
                    v-model="valid"
                    @submit.prevent="addFolderName"
                    ref="foldersForm"
                  >
                    <v-container>
                      <v-row align="center" no-gutters v-if="error">
                        <v-col cols="12">
                          <v-alert type="error" dense>{{ error }}</v-alert>
                        </v-col>
                      </v-row>
                      <v-row align="center" no-gutters>
                        <v-col cols="12" sm="9">
                          <v-text-field
                            color="deep-purple accent-4"
                            v-model="folderName"
                            type="text"
                            :rules="folderNameRules"
                            label="Folder Name"
                            :disabled="loading || superLoading"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" class="d-flex justify-end">
                          <v-btn
                            color="pink darken-2 white--text"
                            :disabled="loading || superLoading || !valid"
                            raised
                            @click="addFolderName"
                          >
                            <v-icon class="mr-2">mdi-folder-plus</v-icon>Add
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>

                  <!-- Folders List -->
                  <v-container>
                    <v-row no-gutters>
                      <v-col>
                        <v-list dense v-for="(folder, i) in folders" :key="i">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title class="text-capitalize">
                                {{ folder }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                              <v-btn
                                color="red"
                                text
                                @click="openDeleteFolderModal(i)"
                                :disabled="loading"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    :loading="loading || superLoading"
                    @click="closeEditFolderDialog"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ActionCard",
  props: ["superLoading"],
  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    dialog: false,
    error: "",
    valid: false,
    folderName: "",
    folderNameRules: [
      (value) => !!value || "Required.",
      (value) =>
        value.length > 2 || "Field length should be greater than 2 characters",
    ],
    folders: [],
    selectedFolderIndex: null,
    deleteFolderDialog: false,
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    closeEditFolderDialog() {
      this.setLoading(true);
      this.dialog = false;
      window.location.reload();
    },

    addFolderName() {
      this.error = "";

      if (this.folders.includes(this.folderName.toLowerCase())) {
        this.error = "This folder already exists :(";
        return;
      }

      this.$store
        .dispatch("addFolderName", this.folderName)
        .then(() => {
          this.$set(
            this.folders,
            this.folders.length,
            this.folderName.toLowerCase()
          );
          this.snackbarText = "Folder added :)";
          this.folderName = "";
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error in adding folder.";
        })
        .finally(() => {
          this.setLoading(false);
          this.snackbar = true;
        });
    },

    openDeleteFolderModal(index) {
      this.selectedFolderIndex = index;
      this.deleteFolderDialog = true;
      // deleteFolderName(i)
    },

    deleteFolderName(index) {
      this.setLoading(true);

      this.$store
        .dispatch("deleteFolderName", this.folders[index].toLowerCase())
        .then(() => {
          this.folders.splice(index, 1);
          this.snackbarText = "Folder deleted.";
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error in deleting folder.";
        })
        .finally(() => {
          this.deleteFolderDialog = false;
          this.snackbar = true;
          this.setLoading(false);
        });
    },

    submitFolderNames() {
      this.setLoading(true);

      const payload = {
        folderNames: this.folders,
      };

      //save foldersName to server
      this.$store
        .dispatch("saveQuestionFolders", payload)
        .then(() => {
          this.snackbarText = "Question Folders saved successfully :)";
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
          this.dialog = false;
        });
    },

    fetchFolders() {
      this.setLoading(true);
      //get folder names from server
      this.$store
        .dispatch("getQuestionFolders")
        .then((res) => {
          if (res) {
            this.folders = res.folderNames;
          }
        })
        .catch(() => {
          this.error = "Network error in fetching folders, please try again.";
        })
        .finally(() => {
          this.setLoading(false);
          this.dialog = false;
        });
    },
  },
  created() {
    this.fetchFolders();
  },
};
</script>
