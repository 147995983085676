<template>
  <div id="questionBank" class="grey lighten-3 fill-height">
    <v-container>
      <v-row justify="center">
        <!-- title -->
        <h1 class="text-title my-9">
          <v-icon class="text-h3 mr-2" color="pink darken-1">
            mdi-cloud-question
          </v-icon>
          Question Bank
        </h1>
      </v-row>

      <v-row justify="center" class="my-4">
        <ActionCard
          :superLoading="superLoading"
          @setSuperLoading="setSuperLoading"
        />
      </v-row>

      <v-row justify="center">
        <QuestionsCard
          :superLoading="superLoading"
          @setSuperLoading="setSuperLoading"
          :enableSelect="false"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ActionCard from "@/components/Home/Manage/QuestionBank/ActionCard.vue";
import QuestionsCard from "@/components/Home/Manage/QuestionBank/QuestionsCard.vue";

export default {
  name: "QuestionBank",
  metaInfo: {
    title: "Question Bank",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Question Bank Page description lorem ipsum dolor sit amet.",
      },
    ],
  },
  components: {
    ActionCard,
    QuestionsCard,
  },
  data: () => ({
    superLoading: true,
  }),
  methods: {
    setSuperLoading(value) {
      this.superLoading = value;
    },

    // loadMathTypeScript() {
    //   this.superLoading = true;
    //   // remain this order (script.src after onLoad and append before onload)
    //   return new Promise((resolve) => {
    //     const scriptElement = document.getElementById("mathTypeScript");
    //     if (!scriptElement) {
    //       var script = document.createElement("script");
    //       document.body.appendChild(script);
    //       script.onload = () => resolve(true);
    //       script.onerror = () => resolve(false);
    //       script.id = "mathTypeScript";
    //       script.src =
    //         "https://cdn.mathjax.org/mathjax/latest/MathJax.js?config=TeX-AMS-MML_HTMLorMML";
    //     } else {
    //       resolve(true);
    //     }
    //   });
    // },

    // initMathJax() {
    //   window.MathJax.Hub.Config({
    //     tex2jax: {
    //       inlineMath: [
    //         ["$", "$"],
    //         ["\\(", "\\)"],
    //       ],
    //     },
    //     "HTML-CSS": {
    //       linebreaks: { automatic: true, width: "container" },
    //     },
    //   });
    // },
  },
  mounted() {
    // this.loadMathTypeScript().then((scriptLoaded) => {
    //   if (!scriptLoaded) {
    //     console.log("mathTypescript not loaded");
    //     // show error ask to reload
    //     this.error = "Network error, please reload :(";
    //   } else {
    //     console.log("mathTypescript loaded!!!!!!!!");
    //     this.initMathJax();
    //     this.superLoading = false;
    //   }
    // });

    this.$nextTick().then(() => (this.superLoading = false));
  },
};
</script>